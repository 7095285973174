<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-row v-if="$route.name === 'TicketingRequestList'">
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        xs="12"
      >
        <v-card-title style="font-size:17px">
          {{ $t('TicketingPopup.loadedAppUser') }}
        </v-card-title>
        <v-data-table
          :mobile-breakpoint="0"
          :headers="ticketing.downloadS3HeadersAppUser"
          :items="ticketing.documentS3ItemsAppUser"
          :footer-props="{
            itemsPerPageAllText: $t('Table.all'),
            itemsPerPageText: $t('Table.rowsPerPage'),
            pageText: `{0}-{1} ${$t('Table.of')} {2}`
          }"
          :no-data-text="$t('Table.noFile')"
        >
          <template
            v-for="h in ticketing.downloadS3HeadersAppUser"
            v-slot:[`header.${h.value}`]="{ header }"
          >
            {{ $t(header.text) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              :size="22"
              class="pt-1"
              @click="dispatchDownloadItem(item)"
            >
              mdi-download
            </v-icon>
            <delete
              :id="item.name"
              class="mb-1"
              @deleted="deleteS3Item(item)"
            />
          </template>
        </v-data-table>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        xs="12"
      >
        <v-card-title style="font-size:17px">
          {{ $t('TicketingPopup.loadedRadarmeteo') }}
        </v-card-title>
        <v-data-table
          :mobile-breakpoint="0"
          :headers="ticketing.downloadS3HeadersRadarMeteo"
          :items="ticketing.documentS3ItemsRadarmeteo"
          :footer-props="{
            itemsPerPageAllText: $t('Table.all'),
            itemsPerPageText: $t('Table.rowsPerPage'),
            pageText: `{0}-{1} ${$t('Table.of')} {2}`
          }"
          :no-data-text="$t('Table.noFile')"
        >
          <template
            v-for="h in ticketing.downloadS3HeadersRadarMeteo"
            v-slot:[`header.${h.value}`]="{ header }"
          >
            {{ $t(header.text) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              :size="22"
              class="pt-1"
              @click="dispatchDownloadItem(item)"
            >
              mdi-download
            </v-icon>
            <div v-if="!isDetail">
              <delete
                :id="item.name"
                class="mb-1"
                @deleted="deleteS3Item(item)"
              />
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        cols="12"
      >
        <v-card-title style="font-size:17px">
          {{ $t('TicketingPopup.loadedAppUser') }}
        </v-card-title>
        <!-- <div class="d-flex align-center">
          <v-card-title style="font-size:17px">
            {{ $t('TicketingPopup.loadedAppUser') }}
          </v-card-title>

          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon
                color="green darken-1"
                v-on="on"
              >
                mdi-help-circle
              </v-icon>
            </template>
            <div
              class="my-1"
              :style="{ 'max-width': '400px' }"
            >
              <span>
                {{ $t('TicketingPopup.tooltipUploadFiles') }}
              </span>
            </div>
          </v-tooltip>
        </div> -->
        <v-data-table
          :mobile-breakpoint="0"
          :headers="ticketing.downloadS3HeadersAppUser"
          :items="ticketing.documentS3ItemsAppUser"
          :footer-props="{
            itemsPerPageAllText: $t('Table.all'),
            itemsPerPageText: $t('Table.rowsPerPage'),
            pageText: `{0}-{1} ${$t('Table.of')} {2}`
          }"
          :no-data-text="$t('Table.noFile')"
        >
          <template
            v-for="h in ticketing.downloadS3HeadersAppUser"
            v-slot:[`header.${h.value}`]="{ header }"
          >
            {{ $t(header.text) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              :size="22"
              class="pt-1"
              @click="dispatchDownloadItem(item)"
            >
              mdi-download
            </v-icon>
            <delete
              :id="item.name"
              class="mb-1"
              @deleted="deleteS3Item(item)"
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-card-text
      v-if="showExtraInformations"
      class="d-flex justify-space-between mt-5"
    >
      <v-divider />
      <p class="mx-8 mt-n4 text-h6">
        {{ $t('TicketingPopup.requestMoreInformation') }}
      </p>
      <v-divider />
    </v-card-text>
    <v-file-input
      v-if="(extraInformation && canInsertFile) || $route.name === 'TicketingRequest'"
      v-model="manualFile"
      accept=".pdf, .txt, .csv, .xlsx, .zip, .rar"
      multiple
      class="mt-4"
      :placeholder="$t('Ticketing.input')"
      prepend-inner-icon="mdi-file-document-outline"
      outlined
      :hint="$t('CustomerLayer.check')"
      truncate-length="50"
      :disabled="disableForm || !canInsertFile"
      @change="confirmFile"
    />
    <!-- <div v-if="extraInformation">
      <v-btn
        v-if="this.$route.name === 'TicketingRequestList'"
        color="#0da344"
        style="color:white"
        small
        :disabled="disableForm || disableAddDocument"
        @click="addDocument()"
      >
        {{ $t('TicketingPopup.addDocument') }}
      </v-btn>
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { S3_BUCKETS } from '@/constants.js';
import { v4 as uuidv4 } from 'uuid';

export default {
  components: {
    delete: () => import('@/components/utils/Delete')
  },
  props: {
    request: {
      type: Object,
      default: null
    },
    requestType: {
      type: String,
      default: ''
    },
    disableForm: {
      type: Boolean,
      default: true
    },
    isDetail: {
      type: Boolean,
      default: true
    },
    ticketingPath: {
      type: Boolean,
      default: false
    },
    extraInformation: {
      type: Boolean
    },
    showExtraInformations: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      manualFile: [],
      overlay: false,
      disableAddDocument: true,
      document_s3_id: '',
      canInsertFile: true
    };
  },
  computed: {
    ...mapState(['ticketing', 'file_transfer', 'app_user', 'policy'])
  },
  watch: {
    $route() {},
    requestType(newRequestType) {
      this.ticketing.documentS3ItemsAppUser.forEach(item => {
        this.$set(item, 'request_type', newRequestType);
      });
    },
    showExtraInformations(newValue) {
      if (newValue) {
        this.checkPermissions();
      }
    }
  },
  async mounted() {
    if (this.$route.name === 'TicketingRequestList') {
      this.ticketing.request_id = this.request.request_id;

      this.checkPermissions();
    }

    if (this.$route.name === 'TicketingRequest') {
      this.ticketing.request_id = uuidv4();
    }

    this.overlay = true;
    await this.storeGetLinkedDocumentS3toTicketingRequest(this.ticketing.request_id);
    this.overlay = false;
  },
  methods: {
    ...mapActions(['deleteS3ItemAction', 'downloadItem', 'setUpload', 'storeGetLinkedDocumentS3toTicketingRequest', 'linkDocumentS3ToTicketingRequestAction', 'deleteDocumentS3Action']),
    async confirmFile() {
      const extensions = ['pdf', 'txt', 'csv', 'xlsx', 'zip', 'rar'];

      for await (const file of this.manualFile) {
        const fileNamePart = file.name.split('.');
        const ext = fileNamePart[fileNamePart.length - 1];

        if (this.validTypes().includes(file.type) || extensions.includes(ext)) {
          this.document_s3_id = uuidv4();

          const params = {
            Bucket: S3_BUCKETS.application(),
            Key: this.ticketingPath ? `meteotrigger/${this.ticketing.request_id}/${file.name}` : `${this.$route.params.id}/${this.ticketing.request_id}/${file.name}`,
            Body: file
          };

          this.ticketing.documentS3ItemsAppUser.push({
            file_name: file.name,
            document_s3_path: params.Key,
            request_id: this.ticketing.request_id,
            request_type: this.requestType,
            app_user_ticket_id: this.request ? this.request.app_user_ticket_id : this.ticketing.app_user_ticket_id,
            document_s3_id: this.document_s3_id,
            document_creator: 'app_user'
          });

          this.disableAddDocument = false;
          await this.setUpload(params);
        } else {
          this.alert = true;
        }
      }

      this.$emit('addedS3');
    },
    async addDocument() { // ref from father
      if (!this.disableForm || !this.disableAddDocument) {
        const addedOnly = this.ticketing.documentS3ItemsAppUser.filter(storeItem => {
          return !this.ticketing.storeDocumentS3Items.some(item => {
            return storeItem.document_s3_id == item.document_s3_id;
          });
        });

        if (addedOnly.length > 0) {
          this.overlay = true;
          await this.linkDocumentS3ToTicketingRequestAction(addedOnly);
          this.overlay = false;
        }
      }
    },
    async deleteS3Item(item) {
      this.ticketing.documentS3ItemsAppUser.splice(this.ticketing.documentS3ItemsAppUser.indexOf(item), 1);
      const params = {
        Bucket: S3_BUCKETS.application(),
        Key: item.document_s3_path
      };
      this.overlay = true;
      await this.deleteS3ItemAction(params);

      if (this.$route.name === 'TicketingRequestList') {
        await this.deleteDocumentS3Action(item.document_s3_id);
      }

      this.overlay = false;
    },
    async dispatchDownloadItem(item) {
      const params = {
        Bucket: S3_BUCKETS.application(),
        Key: item.document_s3_path
      };
      await this.downloadItem(params);
      window.open(this.file_transfer.downloadLink);
    },
    validTypes() {
      const validTypes = {
        DOCUMENTS: {
          PDF: 'application/pdf',
          DOC: 'application/msword',
          DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          PPT: 'application/vnd.ms-powerpoint',
          PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        },
        SPREADSHEETS: {
          EXCEL: 'application/vnd.ms-excel',
          SPREADSHEET_EXCEL: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        ARCHIVES: {
          ZIP: 'application/zip',
          COMPRESSED: 'application/x-zip-compressed',
          SEVEN_ZIP: 'application/x-7z-compressed',
          RAR: 'application/x-rar-compressed'
        },
        CSV: 'text/csv',
        TXT: 'text/plain'
      };

      const getAllValues = (obj) => {
        return Object.values(obj).reduce((values, value) => {
          if (typeof value === 'object') {
            return values.concat(getAllValues(value));
          }

          return values.concat(value);
        }, []);
      };

      return getAllValues(validTypes);
    },
    checkPermissions() {
      const modifyPersonalRequest = this.policy.ticketingServicePermissions.some((item) => item.permission_name == 'Modifica richieste personali');
      const modifyNodeRequest = this.policy.ticketingServicePermissions.some((item) => item.permission_name == 'Modifica richieste del nodo');
      // const modifyUnderNodeRequest = this.policy.ticketingServicePermissions.some((item) => item.permission_name == 'Modifica richieste dei sotto nodi');

      if (this.request?.app_user_ticket_id === this.ticketing?.app_user_ticket_id) {
        this.canInsertFile = this.showExtraInformations || modifyPersonalRequest;
      } else {
        this.canInsertFile = modifyNodeRequest;
      }
    }
  }
};
</script>
